/**
 * @description 挂载在action上的解析charts数据的方法
 */
export default class ActionMethods {
  constructor(data) {
    this.data = data
  }

  // 获取源数据（mock or api）
  getSourceData() {
    const {
      staticData: { source },
      api: { type: apiType, _sourceData }
    } = this.data
    // 是否是mock
    const isMock = apiType === 'mock'

    return isMock ? source : _sourceData
  }

  // 切割第一个，返回剩余的数据
  getCutFirstData() {
    return this.getSourceData().slice(1)
  }

  /**
 * @description 获取某一列的值
 * @param {Number} index 
 * @returns {Array}
 */
  getColumnData(index, isSlice = false) {
    const data = isSlice ? this.getCutFirstData() : this.getSourceData()
    const result = []
    data.forEach(current => {
      const item = current[index]
      result.push(item)
    })
    return result
  }

  /**
   * @description 饼图data转换方法
   * @param {String} labelKey 
   * @param {String} valueKey 
   * @returns {Array}
   */
  convertPieData(labelKey = 'name', valueKey = 'value', isSlice = false) {
    const data = isSlice ? this.getCutFirstData() : this.getSourceData()
    return data.reduce((result, [label, value]) => {
      result.push({
        [labelKey]: label,
        [valueKey]: value
      })
      return result
    }, [])
  }
}