import { d, h } from '@/units'
import { nextTick, reactive, ref, render } from 'vue'
import './index.less'
import _, { cloneDeep } from 'lodash'
import API from '@/api'
import store from '@/store'
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import service from '@/utils/request'
import RequestApi from '@/utils/RequestApi'
import { queueTask } from '@/utils/queueTask'
import { seriesOption, TYPE_WITH_REQUEST } from '@/utils/const'
import { converData, getComponentsFormat, getComponentsType, getQueryVariable } from '@/utils'
import darkJson from '../../public/dark.json'
import defaultJson from '../../public/default.json'
import { message } from 'ant-design-vue'
import useComponentType from '@/hooks/useComponentType'
import { IApiOptions } from '@/types'
import autoAppend from '@/pages/page-editor/views/settingPane/dataTabPane/private/autoAppend'
import { useRoute } from 'vue-router'
import ActionMethods from '@/utils/action-methods'
import { LargeScreenDesignPublishStatus } from '@/enums'

interface IComponentConfigInMainJson {
  anotherName: string
  api: IApiOptions
  id: string
  isDark: boolean
  target: string
  theme: string
  type: string
  uid: string
  option: Record<string, any>
}

export default d({
  name: 'preview',
  setup: (props, context) => {
    setTimeout(() => {
      document.body.style.overflow = 'auto'
      var html = document.getElementsByTagName('html')[0]
      html.style.overflow = 'auto'
    }, 3000);
    document.title = '预览'
    const viewRef = ref<any | null>(null)
    const vNodesEl = ref<any | null>(null)
    let vNodes: any = []
    let myslot: any = []
    let buildJson: any = {}
    const route = useRoute()
    const state = reactive({
      isDark: false,
    })
    const style = reactive({
      width: 1920,
      height: 1080,
      transform: 'scaleY(1) scaleX(1) translate(-50%, -50%)'
    })
    const getScale = () => {
      const w = window.innerWidth / style.width
      const h = window.innerHeight / style.height
      //根据屏幕的变化适配的比例
      const designDraftWidth = style.width
      const designDraftHeight = style.height
      const scale = document.documentElement.clientWidth / document.documentElement.clientHeight < designDraftWidth / designDraftHeight ?
        (document.documentElement.clientWidth / designDraftWidth) :
        (document.documentElement.clientHeight / designDraftHeight)
      return { x: w, y: h, scale }
    }
    const setScale = () => {
      return
      let { scale, y, x } = getScale()
      //style.transform =`translate(-50%, -50%)`
      //style.transform =`scale(${scale}) translate(-50%, -50%)`
      style.transform = 'scaleY(' + y + ') scaleX(' + x + ') translate(-50%, -50%)'
    }
    setScale()
    window.addEventListener('resize', () => {
      setScale()
    })
    const pageID = getQueryVariable('pageid')
    const token = getQueryVariable('token')
    const version = getQueryVariable('version')
    if (token) token.indexOf('Bearer%20') == 0 ? sessionStorage.setItem('token', `Bearer ${token.substring(9)}`) : sessionStorage.setItem('token', `Bearer ${token}`)

    const appendDom = (dom, el) => {
      render(dom, el)
    }
    const initOption = (item) => {
      const ota = buildJson.body.children.find((items: any) => items.id === item.id)
      let option = item.option
      // if (item.api.type == 'mock') {
      //   option.dataset = item.staticData
      // }
      if (item.type == 'chart-pie3D') {
        option = seriesOption(ota.option, ota.staticData)
      }
      return option
    }
    const updataSource = (item, respons, includesEl) => {
      if (respons.data instanceof Array) {
        if (item.api._sourceData) {

          for (let i = 0; i < respons.data.length; i++) {
            item.api._sourceData[i] = respons.data[i] // _this
            // includesEl.el.__vueComponent.__sourceData[i] = respons.data[i] // 别名 常规组件
            includesEl.__sourceData[i] = respons.data[i] // 别名
          }
        }
      } else {
        if (item.api._sourceData) item.api._sourceData = Object.assign(item.api._sourceData, respons.data)
      }
    }
    const parseParams = (uri, params) => {
      const paramsArray = []
      Object.keys(params).forEach(key => params[key] && paramsArray.push(`${key}=${params[key]}`))
      if (uri.search(/\?/) === -1) {
        uri += `?${paramsArray.join('&')}`
      } else {
        uri += `&${paramsArray.join('&')}`
      }
      return uri
    }

    const setProperty = (values) => {
      for (let p in values) {
        document.documentElement.style.setProperty(`--${p}`, values[p])
      }
    }
    const changeTheme = () => {

      (window as any)?.less?.modifyVars(
        state.isDark ? {
          ...darkJson,
        } : {
          ...defaultJson,
          '@layout-header-background': '#fff',
        }
      )
      state.isDark ?
        setProperty({
          'canvasBackground': '#222',
          'rulersBackground': '#2a2a2a',
          'viewBackground': '#333',
          'siderLeftBackground': '#2a2a2a',
          'siderLeftBackgroundHover': 'rgba(0,12,23,0.65)',
          'tableSumBackground': 'transparent',
          'tableFixBackground': 'rgba(0,0,0,0.25)',
        })
        :
        setProperty({
          'canvasBackground': '#ddd',
          'rulersBackground': '#fafafa',
          'viewBackground': '#fff',
          'siderLeftBackground': '#ccc',
          'siderLeftBackgroundHover': 'rgba(13,1,3,0.35)',
          'tableSumBackground': 'transparent',
          'tableFixBackground': 'rgba(255,255,255,0.45)',
        })
      store.dispatch('editor/setDarkMode', state.isDark)
    }
    // 整合当前组件上下游的关系网
    const familyNetwork = item => {
      let stream = callback(item)
      const index = stream.indexOf(item.uid)
      if (index > -1) {
        stream.splice(index, 1)
      }
      return [...new Set(stream)]
    }
    const callback = (item) => {
      let array = []
      const run = items => {
        let streamArr = [
          ...items.api.paramsConfig.downStream,
          ...items.api.paramsConfig.upStream,
        ]
        if (!streamArr.length) return
        buildJson.body.children.forEach(element => {
          streamArr.forEach(id => {
            if (element.uid === id && element.anotherName) {
              // 两数组做对比 如果有相同的值 循环直接跳出 有不同的值执行回调查询
              const isBack = array.find(item => item === id)
              if (isBack) return
              array.push(id)
              run(element)
            }
          })
        })
      }
      run(item)
      return array
    }

    const executeCodeFun = (item) => {
      let familyList = []
      familyList = familyNetwork(item).map(v => {
        let _content = document.querySelector('#' + v).firstChild as any
        let _query = _content.__vueComponent
        const componentItem = buildJson.body.children.find(items => items.id == v)

        let key = componentItem?.anotherName ? componentItem?.anotherName : v
        let _thisDom = _dom.find(e => Object.keys(e) == v)
        return {
          [key]: _query,
          ['_' + key]: Object.values(_thisDom)[0]
        }
      })
      sanboxAction(familyList, item.action, item)
    }

    const sanboxAction = (familyList, action, item) => {

      // 暴露的一些处理action中数据的方法
      const actionMethods = new ActionMethods(item)
      const key = item?.anotherName ? item?.anotherName : item.id
      let tempObj = {}
      let _query = null
      let _content = null
      if (item.type.includes('chart')) {
        // 自己的实体
        _content = document.getElementById(item.id)?.firstChild as HTMLElement
        _query = echarts.getInstanceByDom(_content)
        tempObj[key] = _query
      } else {
        // 自己的实体
        _content = document.querySelector('#' + item.id).firstChild as any
        _query = _content.__vueComponent
        tempObj[key] = _query
      }
      _.set(_query, '__sourceData', item.api?._sourceData || [])
      familyList.forEach(v => {
        tempObj = { ...tempObj, ...v }
      })

      let _el = _content
      let _data = item
      let lodash = _
      let _this = _query
      let _echarts = echarts
      let msg = message
      let _utils = actionMethods
      let nexts = nextTick
      // 把 组件 的onChange 方法 抛出到actions
      // const useChangeEffect = (value: any) => {
      //   const { h } = _query._$handlers?.change[0]
      //   if (!h) return
      //   h(value)
      // }

      /**
       * 更新下游组件
       * @param downList
       * @param value
       * @param key
       */
      interface IUDParams {
        downList: string[]
        value: any
        key?: string
      }

      // 注册方法
      const setupMethod = ({ eventName, cb }) => {
        _query.props.callbackMap.setCallback({
          eventName,
          cb
        })
      }

      // 自定义请求
      const customRequest = ({ url, method, params, data }) => {
        return service.request({
          url,
          method,
          params,
          data
        })
      }

      const getDataSource = () => {
        return _query.props.callbackMap.returnData()
      }

      const updateDownStream = ({ downList, value, key }: IUDParams) => {
        const h = _this?.attrs?.onChange || _this._$handlers?.change[0]?.h
        if (!h) return
        h({ data: value, downs: downList, upStreamKey: key }, 'action-updateDown')
      }

      //抛出 当前dom的change时间
      // const watchData = (obj, item) => {
      //   Object.defineProperty(obj, "__sourceData", {
      //     get() {
      //       return this.___sourceData || 'undefined'
      //     },
      //     set(newVal) {
      //       let _opt = {} as any
      //       _opt.dataset = []
      //       _opt.dataset['source'] = newVal
      //       item.setOption(_opt, false)
      //     }
      //   })
      // }
      // let _watchEffect = watchEffect

      function updateView(news = null, olds = null, fn = null) {
        if (fn) fn(news, olds)
        console.log('视图更新')
      }

      // 重新定义数组原型
      const oldArrayProperty = Array.prototype
      // 创建新对象，原形指向 oldArrayProperty，再扩展新的方法不会影响原型
      const arrProto = Object.create(oldArrayProperty);

      ['push', 'pop', 'shift', 'unshift', 'splice'].forEach(methodName => {
        arrProto[methodName] = function () {
          updateView() // 触发视图更新
          oldArrayProperty[methodName].call(this, ...arguments)
        }
      })

      // 重新定义属性，监听起来（核心）
      function defineReactive(target, key, value, fn) {

        // 深度监听
        observer(value)

        // 核心 API
        Object.defineProperty(target, key, {
          get() {
            return value
          },
          set(newValue) {
            let _value = JSON.stringify(value)
            let _newValue = JSON.stringify(newValue)

            if (_newValue != _value) {
              // 深度监听
              observer(newValue)
              updateView(newValue, value, fn)
              // 设置新值
              // 注意，value 一直在闭包中，此处设置完之后，再 get 时也是会获取最新的值
              value = newValue

              // 触发视图更新

            }
          }
        })
      }

      // 监听对象属性（入口）
      function observer(target, fn = null) {
        if (typeof target !== 'object' || target === null) {
          // 不是对象或数组
          return target
        }

        // 数组的情况
        if (Array.isArray(target)) {
          Object.setPrototypeOf(target, arrProto)
          // target.__proto__ = arrProto
        }

        // 重新定义各个属性（for in 也可以遍历数组）
        for (let key in target) {
          defineReactive(target, key, target[key], fn)
        }
      }


      const _run = () => {
        let _public = ''
        let _params = Object.keys(tempObj)?.map(keys => keys)?.join() || ''
        // FIXBUG: 兼容别名中有点 
        if (_params.includes('.')) {
          const _paramsArr = _params.split('.')
          _params = _paramsArr[_paramsArr.length - 1]
        }
        // console.log('tempObj', tempObj, _params, Object.keys(tempObj)?.map(keys => keys))
        // 因为别名中有点 导致解构失败，暂时只调用action
        _params && (_public = `const { ${_params} } = tempObj \n`)
        const jsStr = _public + action
        try {
          // eval(action)
          eval(jsStr)
        } catch (error) {
          console.log(error)
        }
      }
      _run()
    }

    const payLoad = ({ item, tempDom, loadType = null, resolve = null }) => {
      let lock = false
      const hasAntdTable = item.type === 'antd-table'
      if (item.type == 'box') return
      const { url, method, reload, reloadTime, appendConfig, dataSourceId, dataNode, type: apiType } = item.api
      const { anotherName } = item
      if (['api', 'mod'].includes(apiType) && !dataSourceId) {
        const msg = apiType === 'api' ? '选择数据源！' : '配置数据MOD！'
        message.error(`'组件：${item.id} 未${msg}请先${msg}`)
        return
      }
      if (apiType === 'auto' && !item.anotherName) {
        message.error('组件：' + item.id + '未设置别名！请先设置别名')
        return
      }
      const { query_params } = item.api.paramsConfig

      if (loadType) appendConfig.appendType = loadType
      const { append, appendMax, appendTime, appendType, appendGroupKey, appendPagination } = appendConfig

      const headers = item.api.headers
      let _this, _opt
      const controlCode = item.id

      const objectFormat: number = getComponentsFormat(item.type, apiType)

      const strType = getComponentsType(item.type, 1)
      if (strType[0] == 'chart') {  //仅处理echart
        _this = echarts.getInstanceByDom(document.getElementById(item.id)?.firstChild as HTMLElement)
        if(!_this) {
          console.error('item',item)
          return
        }
        _opt = _this?.getOption()
        const setMock = () => {
          let deepOpt = _.cloneDeep(_opt)
          deepOpt.dataset = [
            {
              source: []
            }
          ]
          deepOpt.dataset = item.staticData
          setTimeout(() => {
            _this.setOption(seriesOption(deepOpt, deepOpt.dataset), false)
          }, 100)
          resolve()
        }
        if (_opt&&_opt.dataset == undefined) _opt.dataset = [{ 'source': [] }] //初始化
  
        if (apiType == 'mock' && !lock) {
          lock = true
          setMock()
        }
      } else { //非chart 类型
        nextTick(() => {
          _this = tempDom.el?.__vueComponent || tempDom.__vueComponent
          _opt = _this.props.option
          const setMock = () => {
            let deepOpt = _.cloneDeep(_opt)
            deepOpt.dataset.source = hasAntdTable ? { columns: [], dataset: { data: [] } } : []
            const { parameter, fixedParams } = item.api.paramsConfig
            if (strType[0] === 'form' && parameter) {
              // deepOpt.defaultValue = route.query[item.api.paramsConfig.parameter]
              deepOpt.parameter = parameter
            }
            _this.setOption(deepOpt, false)
            if (resolve) resolve()
          }
          if (_opt.dataset == undefined) _opt.dataset['source'] = hasAntdTable ? {
            columns: [],
            dataset: { data: [] }
          } : [] //初始化
          // if (TYPE_WITH_REQUEST.includes(apiType) && !lock) {
          //   lock = true
          //   if (_opt.dataset == undefined) _opt.dataset['source'] = hasAntdTable ? {
          //     columns: [],
          //     dataset: { data: [] }
          //   } : [] //初始化
          //   if (hasAntdTable && item.option.isPolling) {
          //     query_params.iPageSize = 9999
          //   }
          //   const requestApi = new RequestApi({
          //     dataSourceId,
          //     reload,
          //     reloadTime,
          //     append,
          //     appendMax,
          //     appendTime,
          //     appendType,
          //     appendGroupKey,
          //     appendPagination,
          //     controlCode,
          //     query_params,
          //     apiType,
          //     objectFormat,
          //     anotherName,
          //     dataNode,
          //     reloadCallBack: (res) => {

          //       const { data } = res
          //       const _data = data ? res.data : []
          //       _opt.dataset = []
          //       if (hasAntdTable && (item.option.isPolling || item.option.isScrollLoadModel)) {
          //         const pageSize = Number(item.option.pageSize) || 10
          //         let _index = 0
          //         const _time = item.option.pollingTime * 1
          //         const totalData = _data.dataset.data
          //         const getData = () => {
          //           let temp
          //           if (_index <= totalData.length - 1) {
          //             temp = _.slice(totalData, _index, _index + pageSize)
          //             _data.dataset = {
          //               ..._data.dataset,
          //               data: temp
          //             }
          //             _opt.dataset['source'] = _data  //重新赋值
          //             _this.setOption(_opt, appendType != 'selfLoad')
          //             _index += pageSize
          //           } else {
          //             _index = 0
          //             getData()
          //           }
          //         }
          //         queueTask(_time * 1000, getData)
          //       } else {
          //         _opt.dataset['source'] = _data  //重新赋值
          //         _this.setOption(_opt, appendType != 'selfLoad')
          //       }
          //     },
          //     appendCallBack: (res) => {

          //       _opt.dataset['source'] = [] // 初始化
          //       if (appendType == 'pagination') {
          //         let pIndex = 0
          //         queueTask(appendTime * 1000, () => {
          //           if (pIndex < res.length) {

          //             if (_opt.dataset.source.length > appendMax - 1) {
          //               for (let i = 0; i < appendPagination; i++) {
          //                 _opt.dataset.source.shift() // 只能用 shift 方法
          //               }
          //             }
          //             _opt.dataset.source.push(...res[pIndex])
          //             let deepOpt = _.cloneDeep(_opt)
          //             _this.setOption(deepOpt, append)
          //             pIndex++
          //           } else {
          //             pIndex = 0
          //           }
          //         })
          //       } else if (appendType == 'group') {
          //         let gIndex = 0
          //         queueTask(appendTime * 1000, () => {
          //           if (gIndex < res.length) {
          //             _opt.dataset.source = res[gIndex]
          //             let deepOpt = _.cloneDeep(_opt)
          //             _this.setOption(deepOpt, false)
          //             gIndex++
          //           } else {
          //             gIndex = 0
          //           }
          //         })
          //       } else {
          //         let sIndex = 0
          //         queueTask(appendTime * 1000, () => {
          //           if (sIndex < res.length) {
          //             if (_opt.dataset.source.length > appendMax - 1) {
          //               _opt.dataset.source.shift() // 只能用 shift 方法
          //             }
          //             _opt.dataset.source.push(res[sIndex])
          //             let deepOpt = _.cloneDeep(_opt)
          //             _this.setOption(deepOpt, append)
          //             sIndex++
          //           } else {
          //             sIndex = 0
          //           }
          //         })
          //       }
          //     },
          //     finishCallBack: (res) => {

          //       updataSource(item, res, _this)
          //       resolve && resolve(res)
          //     },
          //     useMockCallBack: () => {
          //       setMock()
          //     }
          //   })
          //   //requestApi.request()
          // }
          if (apiType == 'mock' && !lock) {
            lock = true
            setMock()
          }
          // if (item.option.processType === 'output') {
          //   if (!item.upStream) return
          //   const _optionS = cloneDeep(_opt)
          //   if (item.api.inheritType === '0') { //继承数据
          //     if (item.upStream.api.paramsConfig.query_params.selectedRow) {

          //       let _data = _.cloneDeep(item.upStream.api.paramsConfig.query_params.selectedRow)
          //       // _optionS.dataset.source = _.omit(_data,['key__'])
          //       _data.map(item => {
          //         delete item.key__
          //       })
          //       _optionS.dataset.data = _data
          //     } else {
          //       _optionS.dataset = !!item.upStream.option.dataset.length
          //         ? { data: item.upStream.option.dataset }
          //         : (item.upStream.staticData.source.dataset || [])  //重新赋值
          //     }

          //     _optionS.hasQueried = true
          //     _optionS.upStream = item.upStream
          //     _this.setOption(_optionS, false)
          //   } else if (item.upStream.api.dataSourceId || item.upStream.api.type === 'auto') { //继承API
          //     lock = true
          //     let _query
          //     if (item.upStream.api.paramsConfig.query_params.selectedRow) {
          //       let _data = _.cloneDeep(item.upStream.api.paramsConfig.query_params.selectedRow)
          //       let _properties = item.api.analyzeProperties
          //       let _value = _data.map(item => item[_properties])
          //       _query = { [item.upStream.api.paramsConfig.parameter]: _value }
          //     }
          //     const params = {
          //       dataSourceId: item.upStream.api.dataSourceId,
          //       controlCode: item.api.paramsConfig.upStream[0],
          //       // controlCode,
          //       query_params: Object.assign(item.api.paramsConfig.query_params, _query),
          //       apiType: item.upStream.api.type,
          //       anotherName: item.upStream.anotherName
          //     }
          //     const _objectFormat: number = getComponentsFormat(item.upStream.type, apiType)
          //     const requestApi = new RequestApi({
          //       ...params,
          //       objectFormat: _objectFormat,
          //       dataNode,
          //       reloadCallBack: (res: any) => {

          //         if (!res?.success) return
          //         if (!res?.data) return message.error(res?.message || '数据不存在')
          //         _optionS.dataset = res.data.dataset || []  //重新赋值
          //         _optionS.hasQueried = true
          //         _optionS.upStream = item.upStream
          //         _this.setOption(_optionS, false)
          //       },
          //       finishCallBack: (res) => {

          //         updataSource(item, res, _this)
          //         resolve && resolve(res)
          //       },
          //       useMockCallBack: (res) => {

          //         if (!res?.data) return message.error(res?.message || '数据不存在')
          //       }
          //     })
          //     // requestApi.request()
          //   }
          // }
        })
      }
    }

    // 创建css文件并加载
    const loadCreateCss = (code) => {
      const stylesheet = document.createElement('style')
      stylesheet.type = 'text/css'
      stylesheet.innerHTML = code
      const head = document.getElementsByTagName('head')[0]
      head.appendChild(stylesheet)
    }

    /**
     * 获取 当前组件的所有上游组件（包括上游组件的）
     * @param upStreamList 上游组件列表
     * @param recursion 是否递归
     */
    const getUpStreamParams = (upStreamList: string[], recursion?: boolean): Record<string, any> => {
      interface IUpParamsItem {
        id: string
        params: Record<string, any>
        level: number
      }

      if (!upStreamList?.length) return {}
      let res: Record<string, any> = {}
      let upParamsList: IUpParamsItem[] = []
      const emptyParams: string[] = []
      const _getParams = (list: string[], level: number) => {
        list.forEach((id: string) => {
          //如果 上游参数列表 中有 该id，说明有闭环的上下游关系，防止死循环，跳出本次遍历
          if (upParamsList?.find(p => p.id === id)) return
          const upStreamItem: IComponentConfigInMainJson = buildJson.body.children?.find(c => c.id === id)//上游组件配置项
          const { query_params = {}, parameter = '', upStream = [] } = upStreamItem.api.paramsConfig
          const _params = {
            ...query_params,
          }
          if (parameter) {
            let _content: any = document.querySelector('#' + id).firstChild
            const _value = _content.__vueComponent?.isValue || ''
            if (_value && _value.length != 0) {
              _params[parameter] = _value
            } else {
              !emptyParams.includes(parameter) && emptyParams.push(parameter)
              _params && delete _params[parameter]
            }
          }
          if (upStreamItem.option.pageSize) _params.iPageSize = upStreamItem.option.pageSize
          upParamsList.push({ id, params: _params, level })
          if (upStream?.length && recursion) {
            _getParams(upStream, level + 1)
          }
        })
      }
      _getParams(upStreamList, 0)
      upParamsList = upParamsList.sort((a, b) => b.level - a.level)//做一次排序，确保取就近组件的参数
      upParamsList.forEach((up: IUpParamsItem) => {
        res = {
          ...res,
          ...up.params
        }
      })
      //追加逻辑，删除 为空的parameter
      emptyParams.forEach((p: string) => {
        delete res[p]
      })
      return res
    }

    /**
     * 重新更新下游组件数据 并渲染
     * @param item
     * @param downStream
     * @param value
     * @param upParamsKey
     */
    const rePayLoad = (item: any, downStream: string[], value: any, upParamsKey?: string) => {
      let downStreamList = buildJson.body.children.filter((items: any) => downStream.includes(items.id))
      downStreamList?.forEach((downItem: any) => {
        const _item = cloneDeep(downItem)
        let _thisDom = document.querySelector('#' + _item.id).firstChild as any
        const { parameter, query_params } = item.api.paramsConfig
        _item.api.paramsConfig.query_params = {
          ...query_params,//上游的参数
          ...downItem.api.paramsConfig.query_params,
        }
        //上游组件的 parameter
        if (parameter && value && value.length != 0) {
          _item.api.paramsConfig.query_params[parameter] = value
        }
        upParamsKey && (_item.api.paramsConfig.query_params[upParamsKey] = value)
        //组件自身如果有 pageSize
        if (_item.option.pageSize) _item.api.paramsConfig.query_params.iPageSize = _item.option.pageSize
        payLoad({ item: _item, tempDom: _thisDom, loadType: 'selfLoad' })
      })
    }

    /**
     * 渲染组件
     * @param item 组件配置项
     */
    const renderDom = (item) => {
      if (!item.id) return ''
      let renderLock = true
      const Icompontent: any = useComponentType(item.type)
      const strType = getComponentsType(item.type, 1)
      let tempDom
      tempDom = h(Icompontent, {
        onChange: (e, key = 'event') => {
          const { upStream = [], downStream = [] } = item.api.paramsConfig//当前上游配置,当前下游配置
          if (key.includes('action')) {
            const { downs, data, upStreamKey } = e
            const aType = key.split('-')[1]
            if (aType === 'updateDown') {
              upStream.length && (item.api.paramsConfig.query_params = {
                ...getUpStreamParams(upStream, true),
              })
              downs.length && rePayLoad(item, downs, data, upStreamKey)
            }
            return
          }
          if (strType[0] == 'form') {
            if (strType[1] == 'button') {
              buildJson.body.children?.forEach((obItem: any) => {  //循环所有组件
                new Promise<void>((resolve, reject) => {
                  let _upStreamList = obItem.api.paramsConfig.upStream || []  //当前上游配置
                  if (_upStreamList.length) {
                    _upStreamList.forEach((id: string, index: any) => {
                      let upStreamItem = buildJson.body.children.find((items: any) => items.id == id)
                      let _content = document.querySelector('#' + id).firstChild as any
                      let _value = _content.__vueComponent.isValue || ''
                      if (upStreamItem) {
                        const { parameter, fixedParams } = upStreamItem.api.paramsConfig //当前组件 传入参数
                        // let param = parameter ? {
                        //   [parameter]: _value || ''
                        // } : {}
                        // if (obItem.option.pageSize) obItem.api.paramsConfig.query_params.iPageSize = obItem.option.pageSize
                        // if (obItem?.api?.paramsConfig?.query_params?.iCurrPage) obItem.api.paramsConfig.query_params.iCurrPage = '1'

                        // if (_value || _value == '') {
                        //   if (!obItem.api.paramsConfig['query_params']) obItem.api.paramsConfig['query_params'] = { ...param }
                        //   const currentKeys = Reflect.ownKeys(obItem.api.paramsConfig['query_params'])
                        //   currentKeys.forEach((_key, index) => {
                        //     if (_value?.length != 0) {
                        //       obItem.api.paramsConfig['query_params'][parameter] = _value
                        //     } else {
                        //       delete obItem.api.paramsConfig['query_params'][parameter]
                        //     }
                        //   })
                        // } else {
                        //   obItem.api.paramsConfig['query_params'] && delete obItem.api.paramsConfig['query_params'][parameter]
                        // }

                        if (_value && _value?.length != 0 || fixedParams) {
                          _.set(obItem.api.paramsConfig, ['query_params', parameter], _value)
                        } else {
                          const { query_params = {} } = obItem.api.paramsConfig
                          Object.keys(query_params).length && delete obItem.api.paramsConfig.query_params[parameter]
                        }
                      }
                      if (index == _upStreamList.length - 1) resolve()
                    })
                  }
                  if (obItem.option.pageSize) obItem.api.paramsConfig.query_params.iPageSize = obItem.option.pageSize
                  if (obItem?.api?.paramsConfig?.query_params?.iCurrPage) obItem.api.paramsConfig.query_params.iCurrPage = '1'
                }).then(() => {
                  if (obItem.option.processType === 'output') return
                  payLoad({
                    item: obItem,
                    tempDom: document.querySelector('#' + obItem.id).firstChild,
                    loadType: 'selfLoad'
                  })
                })
              })
            }
            if (item.api.paramsConfig.parameter && item.api.paramsConfig.actionEvent == 0) { //立即触发方式
              if (['selectedRow', 'clickRow', 'iCurrPage'].includes(key)) return
              const { parameter, fixedParams } = item.api.paramsConfig //当前组件的 传入参数
              // const _value = e?.target?.value || e //当前 onchange 事件传入的参数
              const _value = typeof e === 'object' ? e?.target?.value : e //当前 onchange 事件传入的参数
              //当前组件 的 所有下游组件
              let downStreamItems = buildJson.body.children.filter((items: any) => item.api.paramsConfig.downStream.includes(items.id))
              downStreamItems?.forEach((downItem: any) => {
                //下游dom
                let _thisDom = document.querySelector('#' + downItem.id).firstChild as any
                // if (!Object.keys(query_params).length) query_params[parameter] = _value
                // Reflect.ownKeys(query_params).map((p, index) => {
                //   if (Object.keys(p)[0] == parameter) {
                //     query_params[p] = _value
                //   } else {
                //     if (_value.length != 0) {
                //       query_params[parameter] = _value
                //     } else {
                //        Object.keys(query_params).length && delete query_params[parameter]
                //     }
                //   }
                // })
                if (_value && _value?.length != 0 || fixedParams) {
                  _.set(downItem.api.paramsConfig, ['query_params', parameter], _value)
                } else {
                  const { query_params = {} } = downItem.api.paramsConfig
                  Object.keys(query_params).length && delete downItem.api.paramsConfig.query_params[parameter]
                }
                payLoad({ item: downItem, tempDom: _thisDom, loadType: 'selfLoad' })
              })
            }
          } else if (strType[0] == 'antd') {
            const _itemC: any = cloneDeep(item)
            if (upStream.length) {
              //获取所有上游组件 包括 上游的上游
              _itemC.api.paramsConfig.query_params = {
                ...getUpStreamParams(upStream, true),
                ..._itemC.api.paramsConfig['query_params'],
                [key]: e
              }
              if (_itemC.option.pageSize) _itemC.api.paramsConfig.query_params.iPageSize = _itemC.option.pageSize
            }
            if (downStream.length) {
              if (_itemC.api.paramsConfig.parameter && _itemC.api.paramsConfig.actionEvent == 0) { //立即触发
                if (['clickRow', 'selectedRow', 'columnClick'].includes(key)) {
                  rePayLoad(_itemC, downStream, e)
                }
              } else {
                _itemC.api.paramsConfig['query_params'] = {
                  ..._itemC.api.paramsConfig['query_params'],
                  [key]: e
                }
                if (!['selectedRow', 'clickRow', 'columnClick', 'iCurrPage'].includes(key)) {
                  payLoad({
                    item: _itemC,
                    tempDom: document.querySelector('#' + _itemC.id).firstChild
                  })
                }
              }
            }

            if (['iCurrPage'].includes(key)) {
              _itemC.api.paramsConfig['query_params'] = { ..._itemC.api.paramsConfig['query_params'], [key]: e }
              payLoad({
                item: _itemC,
                tempDom: document.querySelector('#' + item.id).firstChild,
                loadType: 'selfLoad'
              })
            }
          } else if (strType[0] == 'chart') {
            // upStream.length && (item.api.paramsConfig.query_params = {
            //   ...getUpStreamParams(upStream, true),
            //   ...item.api.paramsConfig['query_params'],
            //   [key]: e
            // })
            // downStream.length && rePayLoad(item, downStream, e)
          }
          if (item.option.processType === 'output') {
            const { iPageSize, inheritType, paramsConfig } = item.api
            paramsConfig.query_params = {}
            let _upStream = buildJson.body.children.find((c: any) => c.id == paramsConfig.upStream[0])
            if (!_upStream) return
            // 目前应该 最多只有一个上游
            // item.api.upApi = _upStream.api
            item.upStream = _upStream
            if (inheritType === '1') {
              const doubleUSIds = _upStream.api.paramsConfig.upStream
              doubleUSIds.forEach(id_d => {
                const _this = buildJson.body.children.find((c: any) => c.id == id_d)
                if (!_this) return
                let _content = document.querySelector('#' + _this.id).firstChild as any
                paramsConfig.query_params[_this.api.paramsConfig?.parameter] = _content.__vueComponent.isValue || ''
              })
              paramsConfig.query_params = { ...paramsConfig.query_params, ..._upStream.api.paramsConfig.query_params }
              paramsConfig.query_params.iPageSize = iPageSize
            }
            payLoad({ item, tempDom: document.querySelector('#' + item.id).firstChild })
          }
        },
        class: item.type,
        theme: item.isDark ? 'dark' : 'light',
        option: initOption(item),
        staticData: item.staticData,
        locationInfo: item.style,
        autoresize: true,
        dataSource: item.api.type,
        onFinished: () => {
          if (renderLock) {
            if (strType[0] == 'antd') {
              if (item.option.pageSize) {
                item.api.paramsConfig['query_params'] = { 'iPageSize': item.option.pageSize, 'iCurrPage': '1' }
              } else {
                item.api.paramsConfig['query_params'] = { 'iCurrPage': '1' }
              }
            }
            new Promise(resolve => {
              if (item.option.isPolling || item.option.isScrollLoadModel) {
                payLoad({ item, tempDom, loadType: 'selfLoad', resolve })
              } else {
                payLoad({ item, tempDom, resolve })
              }
            }).then((res: any) => {
              if (item?.action) {

                item.api._sourceData = res?.data || []
                executeCodeFun(item)
              }
            })
            renderLock = false
          } else {
            return
          }
        }
      })
      _dom.push({ [item.id]: tempDom })
      _domCopy[item.id] = tempDom
      return tempDom
    }
    let _dom = []
    let _domCopy = {}
    if (pageID) {
      const params: Record<string, any> = {
        isPublish: LargeScreenDesignPublishStatus.Draft
      }
      version && (params.version = version)
      API.pageManage.getPageInfo(pageID, token, params).then((res: any) => {
        if (res.data.jsonConfig == '') return
        buildJson = JSON.parse(res.data.jsonConfig)
        const {
          width,
          height,
          backgroundImage,
          backgroundColor,
          backgroundOrigin,
          backgroundPosition,
          backgroundRepeat,
          backgroundSize,
          customStyle
        } = buildJson?.body?.style
        document.body.style.display = 'table'
        // style.width = parseInt(width || 1920)
        // style.height = parseInt(height || 1080)
        // setScale()
        loadCreateCss(customStyle)
        const appStyle = document.getElementById('app').style
        // appStyle.margin = 'auto'
        // appStyle.display = 'flex'
        appStyle.position = buildJson.body.style.isMAXPageHeight ? 'absolute' : 'relative'
        // appStyle.justifyContent = 'center'
        // appStyle.alignItems = 'center'
        appStyle.width = '100%'
        appStyle.height = '100%'
        const {
          isDark
        } = buildJson
        // viewRef.value.style.width = `${width}px`
        // 下面两行hh注释
        viewRef.value.style.width = buildJson.body.style.isMAXPageWidth ? '100%' : `${width}px`
        viewRef.value.style.height = buildJson.body.style.isMAXPageHeight ? '100%' : `${height}px`
        viewRef.value.style.backgroundImage = `url(${backgroundImage})`
        viewRef.value.style.backgroundColor = backgroundColor
        viewRef.value.style.backgroundRepeat = backgroundRepeat
        viewRef.value.style.backgroundPosition = backgroundPosition
        viewRef.value.style.backgroundSize = backgroundSize
        viewRef.value.style.backgroundOrigin = backgroundOrigin
        let listDome: any = []
        buildJson.body.children?.map((item: any, index: number) => {
          item.isDark = isDark
          item.theme = isDark ? 'dark' : 'light'
          if (item.id) {
            const newDom = h('div', {
              id: item.id,
              class: 'box',
              // style: () => item.option.resize ? {
              //   width: item.option.resize[0] ? '100%' : 'auto',
              //   height: item.option.resize[1] ? '100%' : 'auto',
              // } : {},
              style: {
                top: `${item.style.top}px`,
                left: `${item.style.left}px`,
                position: item.option.isFixedPageSizeH || item.option.isFixedPageSizeW ? 'fixed' : 'absolute',
                overflow: item.option.isFixedPageSizeH || item.option.isFixedPageSizeW ? 'auto' : 'hidden',
                backgroundImage: `url(${item.backgroundImage || ''})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundColor: item.backgroundColor || '',
                height: (item.option.resize && item.option.resize[1]) || item.option.isFixedPageSizeH ? '-webkit-fill-available' : `${item.style.height}px`,
                width: (item.option.resize && item.option.resize[0]) || item.option.isFixedPageSizeW ? '100%' : `${item.style.width}px`,
                //width: `${item.style.width}px`,
                zIndex: item.style.zIndex,
              },
            },
              renderDom(item)
            )
            listDome.push(newDom)
            myslot = [...myslot, newDom]
          }
        })
        vNodes = h('span', { ref: vNodesEl }, myslot)
        appendDom(vNodes, viewRef.value)
        state.isDark = JSON.parse(res.data.jsonConfig).isDark
        changeTheme()
        // 请求auto数据源
        nextTick(() => {
          getAutoDataSource(buildJson.body.children, _domCopy)
        })
      })
    }
    // 处理echarts控件
    const handleEcharts = (item, resData, strType, objectFormat) => {
      const _this = echarts.getInstanceByDom(document.getElementById(item.id)?.firstChild as HTMLElement)
      const _opt = _this?.getOption()
      _opt.dataset = [{ 'source': [] }]
      const reloadCallBack = (resData) => {
        let _data = resData || []
        if (strType[1] == 'heatmap') {
          _data[0].length-- //热力图特殊处理
        }
        _data = converData(_data, objectFormat)
        _opt.dataset[0]['source'] = _data || []  //重新赋值
        _this.setOption(seriesOption(_opt, _opt.dataset[0]), false)
      }
      const updataSource = (that) => {
        item.api._sourceData = resData
        that.__sourceData = resData // 别名
      }
      reloadCallBack(resData)
      updataSource(_this)
      executeCodeFun(item)
    }
    // 处理除echarst的其他控件
    const handleOtherComponent = (item, tempDom, resData) => {
      const _this = tempDom.el?.__vueComponent || tempDom.__vueComponent
      const _opt = _this.props.option
      const hasAntdTable = item.type === 'antd-table'
      const { type, appendType } = item.api
      if (TYPE_WITH_REQUEST.includes(type)) {
        _opt.dataset['source'] = hasAntdTable ? {
          columns: [],
          dataset: { data: [] }
        } : [] //初始化
        _opt.dataset['source'] = resData  //重新赋值
        _this.setOption(_opt, appendType != 'selfLoad')
        const updataSource = (that) => {
          that.staticData = resData
          item.api._sourceData = resData
          that.__sourceData = resData // 别名
        }
        updataSource(_this)

        executeCodeFun(item)
      }
    }
    // 对数据源去重
    const handleDuplicateRemovaList = (filterAutoDataSource) => {
      const duplicateRemovaList = []
      filterAutoDataSource.forEach(item => {
        const itype = item.anotherName.split('.')[0]
        const target = duplicateRemovaList.find(jtem => {
          const jtype = jtem.anotherName.split('.')[0]
          if (itype === jtype) return jtem
        })
        if (!target) {// 不存在才插入
          if (item.anotherName && item.anotherName.includes('.')) {
            item.isCompoundDataSource = true
          }
          duplicateRemovaList.push(item)
        } else {
          if (item.anotherName && item.anotherName.includes('.')) {
            target.isCompoundDataSource = true
          }
        }

      })
      return duplicateRemovaList
    }
    // 筛选出auto数据源
    const handleFilterAutoDatasource = (list) => {
      const filterAutoDataSource = list.filter(item => {
        if (item.api && item.api.type === 'auto') return item
      })
      return filterAutoDataSource
    }
    function getURLParameters(url) {
      // 先按照?进行分割 取最后一个?后面的参数
      const utlList = (url || location.href).split('?')
      if (!utlList.length) return {}
      const url_ = utlList[utlList.length - 1]
      if (!url_) return {}
      const obj = (url_.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
        (a, v) => ((a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a),
        {}
      )
      for (const k in obj) {
        obj[k] = typeof obj[k] === 'string' ? decodeURIComponent(obj[k]) : obj[k]
      }
      return obj
    }
    const queryString = getURLParameters(location.href)
    delete queryString.token
    // 单独剥离auto数据源请求出来
    // 对数据进行去重
    const getAutoDataSource = (list, _domCopy) => {
      // 所有的auto数据源控件
      const filterAutoDataSource = handleFilterAutoDatasource(list)
      // 对auto数据源控件去重
      const duplicateRemovaList = handleDuplicateRemovaList(filterAutoDataSource)
      // 对数据源进行循环请求
      duplicateRemovaList.forEach(item => {
        const objectFormat: number = getComponentsFormat(item.type, '垃圾参数没有用')
        const DatasetProp = item.anotherName.split('.')[0]
        const hasAntdTable = item.type === 'antd-table'
        let query_params = item.api.paramsConfig['query_params'] || {}
        if (hasAntdTable) {
          if (Object.keys(query_params).length === 0) {
            query_params.iCurrPage = 1
          }
          if (item.option.isPolling) {
            query_params.iPageSize = 9999
          }
        }
        query_params.identification = '2024-4-4'
        const nowReqJson = {
          request_body: {},
          request_header: {},
          rest_params: {},
          query_params: Object.assign(queryString, query_params || {}),
        }

        const param = {
          controlCaption: DatasetProp, //别名 必填, 按照点分割 取第一个
          controlCode: item.id, //控件唯一名称 必填
          dyncApiId: '',// 非必填
          isAutomaticDataSource: true,// 保持为true
          isPublish: LargeScreenDesignPublishStatus.Draft,// 草稿
          nowReqJson: JSON.stringify(nowReqJson),// 请求参数
          objectFormat: item.isCompoundDataSource ? -1 : objectFormat,// 这个值要动态获取,带有点的是复合接口，一个数据源对应多个字段（user.a,user.b）
          pageId: pageID // pageID
        }
        API.pageManage.requestApi(param).then(res => {
          // 筛选出数据源对应的控件，根据别名来识别。
          const filterTarget = filterAutoDataSource.filter(jtem => {
            // 第一个参数是数据源，第二个参数是数据源中的字段数据集
            const [first] = jtem.anotherName.split('.')
            if (first === DatasetProp) {
              return item
            }
          })
          filterTarget.forEach(xitem => {
            let resData: any = []
            const [first, second] = xitem.anotherName.split('.')
            if (second) {// 说明是复合数据集，点后面的才是控件需要的数据
              resData = res.data[second]
            } else {
              resData = res.data
            }
            const tempDom = _domCopy[xitem.id]
            const strType = getComponentsType(xitem.type, 1)// 获取控件类型 ：比如 chart-line chart-bar
            if (strType[0] == 'chart') {// echarts类型图表特殊处理
              handleEcharts(xitem, resData, strType, objectFormat)

            } else {// 其他图表再特殊处理： 里面又要分是不是表格和其他。。。 
              handleOtherComponent(xitem, tempDom, resData)
            }
          })
        })
      })
    }

    const VNode = {
      render() {
        return h('div',
          {
            ref: viewRef,
            style: {
              // transformStyle: 'preserve-3d',
              // transformStyle: 'inherit',
              //transformStyle: (buildJson.body?.style?.isMAXPageWidth || buildJson.body?.style?.isMAXPageHeight) ? 'inherit' : 'preserve-3d',
              // position: 'fixed',
              // top: '50%',
              // left: '50%',
              // 'transform-origin': '0 0',
              // transition: '0.3s',
              // 'width': `${style.width}px`,
              // 'height': `${style.height}px`,
              // 'transform': `${style.transform}`
            }
          },
          context.slots.default
        )
      }
    }
    return () => h(VNode)
  }
})
